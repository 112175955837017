import {createResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import {DEFAULT_QUERY} from "../../../util/util-constants";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import React from "react";
import {getPartLocationsFields} from "./index";
import {useDispatch} from "react-redux";

export default function PartLocationsModal({
                                               selectedItem,
                                               show,
                                               onClose,
                                               query,
                                               getResourcePath,
                                               dispatchCreateResource,
                                               dispatchUpdateResource,
                                               canSubmit,
                                               translate
                                           }) {
    const dispatch = useDispatch();

    function createPartLocations(data) {
        if (dispatchCreateResource) {
            dispatch(dispatchCreateResource(data));
        } else {
            dispatch(createResource(data));
        }
    }

    function updatePartLocations(data) {
        if (dispatchUpdateResource) {
            dispatch(dispatchUpdateResource(data));
        } else {
            dispatch(updateResource(data));
        }
    }

    return (
        <ModalSaveResource
            title={!!selectedItem.ProductServiceLocationID ? "Edit part location" : "Create part location"}
            widthClass="max-w-3xl"
            gridColsClass={"grid-cols-12"}
            show={show}
            translate={translate}
            fields={getPartLocationsFields(selectedItem)}
            canSubmit={canSubmit}
            onClose={onClose}
            onSubmit={(params) => {
                if (params) {
                    if (selectedItem.ProductServiceLocationID) {
                        updatePartLocations({
                            user: LocalStorage.get('user'),
                            params: Object.assign(
                                params,
                                {ProductServiceLocationID: selectedItem.ProductServiceLocationID}
                            )
                            ,
                            query: query,
                            resource: getResourcePath(),
                            piggyResource: getResourcePath(),
                            errorMessage: true,
                            successMessage: `Part location has been updated`,
                        });
                    } else {
                        createPartLocations({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: query,
                            resource: getResourcePath(),
                            piggyResource: getResourcePath(),
                            errorMessage: true,
                            successMessage: `Part location has been added`,
                        });
                    }
                    onClose();
                }
            }}

            metadata={{
                ProductServiceLocationParentID: {
                    api: 'api/' + getResourcePath(),
                    query: DEFAULT_QUERY,
                    searchMap: (item) => ({
                        value: item.ProductServiceLocationID,
                        label: item.ProductServiceLocation
                    })
                }
            }}
        />
    )
}