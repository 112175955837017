import React, {useEffect, useState} from "react";
import {TruckIcon} from "@heroicons/react/20/solid";
import LocalStorage from "../../common/util/localStorage";
import Resources from "../../data/services/resources";
import {getResource} from "../../data/actions/resource";
import {classNames} from "../../common/util/util-helpers";
import {toFrontDateTime} from "../../common/util/util-dates";
import {Link} from "react-router-dom";
import ClipboardDocumentListIcon from "@heroicons/react/20/solid/ClipboardDocumentListIcon";
import moment from "moment";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../util/util-constants";
import StackedListModal from "../../common/components/display-data/stacked-list/stacked-list-modal";
import Calendar from "./calendar";
import {useDispatch, useSelector} from "react-redux";
import {getProp} from "../../util/util";
import Layout from "../../common/components/layout";
import {getUser} from "../../common/util/util-auth";
import {CalendarDaysIcon} from "@heroicons/react/24/outline";

export default function CalendarTab({history, match, translate}) {
    const user = getUser();
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, 'isLoading', false)
    const data = getProp(resource.data, 'list', [])

    const [isShowMoreModalOpen, setIsShowMoreModalOpen] = useState(false);
    const [selectedDayEvents, setSelectedDayEvents] = useState([]);


    const toggleShowMoreModal = (day) => {
        if (isLoading) return null;

        setIsShowMoreModalOpen(!isShowMoreModalOpen);
        setSelectedDayEvents(day.events);
    }

    const toggleShowSquashedEventsModal = (events) => {
        if (isLoading) return null;

        setIsShowMoreModalOpen(!isShowMoreModalOpen);
        setSelectedDayEvents(Object.values(events).flat(1));
    }

    const fetchCalendarData = (year, month, additionalQuery = {}) => {
        const StartDate = moment(year + "-" + month, "YYYY-MM").subtract(1, 'week').format("YYYY-MM-DD");
        const EndDate = moment(year + "-" + month, "YYYY-MM").endOf('month').add(1, 'week').format("YYYY-MM-DD");

        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrderCalendar,
            query: Object.assign(additionalQuery, {StartDate, EndDate})
        }))
    }

    const [events, setEvents] = useState([]);

    useEffect(() => {
        let events = data.reduce((memo, event) => {
            event.Date = convertDate(event.StartDate);

            event.RoundDownMinutesDate = roundDownMinutes(event.Date);

            const eventDate = event.Date.split(' ')[0];

            if (!memo[eventDate]) {
                memo[eventDate] = [];
            }
            memo[eventDate].push(event);
            return memo;
        }, {});

        // Sort daily events by time
        const days = Object.keys(events);
        days.forEach(day => {
            events[day].sort((a, b) => {
                return moment(a.Date, DEFAULT_DATABASE_DATETIME_FORMAT).unix() - moment(b.Date, DEFAULT_DATABASE_DATETIME_FORMAT).unix()
            });
        })

        setEvents(events);

    }, [data.length]);

    const getEvent = (event) => {
        return (
            <Link
                target={"_blank"} rel={"noopener noreferrer"} to={`/work-orders/${event.WorkOrderID}`}
                className="block px-4 py-4 sm:px-6 border-b border-tm-gray-300"
            >
                <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-primary truncate font-semibold flex">
                        <TruckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-500" aria-hidden="true"/>
                        {`#${event.WorkOrderNumber} - Bay: ${event.BayNumber}`}

                    </p>
                    <div className="ml-2 flex-shrink-0 flex">
                        <div className="mt-2 flex items-center text-sm text-tm-gray-500 sm:mt-0">
                            <CalendarDaysIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400"
                                              aria-hidden="true"/>
                            <div className="w-36 text-right">{toFrontDateTime(event?.StartDate)}</div>
                        </div>
                    </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                        <p className="mt-2 flex items-center text-sm text-tm-gray-700 sm:mt-0">
                            <ClipboardDocumentListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400"
                                                       aria-hidden="true"/>
                            {event?.ApptType}
                        </p>
                    </div>
                    {!!event?.EndDate && (
                        <div className="mt-2 flex items-center text-sm text-tm-gray-500 sm:mt-0">
                            <CalendarDaysIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-tm-gray-400"
                                              aria-hidden="true"/>
                            <div className="w-36 text-right">{toFrontDateTime(event?.EndDate)}</div>
                        </div>
                    )
                    }
                </div>
            </Link>
        )
    }

    const renderDayViewEvents = (events, dayOfTheWeekClass = undefined) => {
        if (!events) return null;


        events = squashEvents(events);

        return events.filter(event => !event.hidden).map(event => {
            const eventMinutes = moment(event.RoundDownMinutesDate, "YYYY-MM-DD HH:mm:ss").diff(moment(event.RoundDownMinutesDate, 'YYYY-MM-DD'), 'minutes');

            const eventStartPosition = !!eventMinutes ? (eventMinutes / 5) + 2 : 0;

            const eventDuration = 3;

            getEventProps(event);

            const ElementTag = !!event.squashedEvents ? "div" : event.ElementTag;
            const onClick = !!event.squashedEvents ? () => toggleShowSquashedEventsModal(event.squashedEvents) : event.onClick;

            return (
                <li
                    key={eventStartPosition}
                    className={classNames(
                        event.hidden ? "hidden" : "", "relative mt-px flex",
                        dayOfTheWeekClass
                    )}
                    style={{gridRow: eventStartPosition + ' / span ' + eventDuration}}
                >
                    <ElementTag
                        key={event.id}
                        onClick={onClick}
                        target={!!event.route ? "_blank" : undefined}
                        rel={!!event.route ? "noopener noreferrer" : undefined} to={event.route}
                        data-id={event.dataID}
                        className={classNames("flex-auto truncate font-medium text-tm-gray-400 leading-tight flex items-center px-2.5 bg-tm-gray-100 hover:bg-tm-gray-200")}>
                        {!!event.Icon && !event.squashedEvents && (
                            <event.Icon className={
                                classNames(
                                    event.isEndDateEvent ? event.endDateIconColor : event.iconColor,
                                "flex-shrink-0 w-5 h-5 mr-2"
                            )}/>
                        )}

                        <span className="font-semibold text-tm-gray-900">
                              {`#${event.WorkOrderNumber} - Bay: ${event.BayNumber}`}
                        </span>

                        <span
                            className={classNames(" ml-1 block flex-none text-tm-gray-700 leading-tight")}
                        >
                        {getEventTime(event)}
                    </span>
                    </ElementTag>
                </li>
            )
        })
    }

    const renderMonthlyViewEvents = (day) => {
        const events = day.events;

        const maxNoVisible = 7;
        const eventsNo = events.length;
        const hasViewMoreButton = eventsNo > maxNoVisible

        // Skeleton loader
        if (isLoading) return (
            <div className="mt-1 animate-pulse space-y-2">
                {Array.from(Array(maxNoVisible).keys()).map(i => <div key={i} className="h-5 bg-tm-gray-100 rounded"/>)}
            </div>
        );

        let renderedEvents = events.filter((event, i) => i <= maxNoVisible - (hasViewMoreButton ? 2 : 1)).map(event => {
            getEventProps(event);

            return (
                <event.ElementTag key={event.Title} onClick={event.onClick}
                                  target={!!event.route ? "_blank" : undefined}
                                  rel={!!event.route ? "noopener noreferrer" : undefined} to={event.route}
                                  data-id={event.dataID}
                                  className={classNames("hover:bg-tm-gray-100", "flex w-full cursor-pointer relative z-10 p-1 rounded-btn group overflow-hidden")}
                >
                    <div className="">
                        <p className={classNames("flex-auto truncate font-medium text-tm-gray-400 leading-tight flex items-center")}>
                            {!!event.Icon && (
                                <event.Icon className={
                                    classNames(
                                        event.isEndDateEvent ? event.endDateIconColor : event.iconColor,
                                        "flex-shrink-0 w-5 h-5 mr-2"
                                    )
                                }/>
                            )}

                            <span className="font-semibold text-tm-gray-900">
                               {`#${event.WorkOrderNumber} - Bay: ${event.BayNumber}`}
                            </span>

                            <span
                                className={classNames(" ml-1 block flex-none text-tm-gray-700 leading-tight")}
                            >
                            {getEventTime(event)}
                        </span>
                        </p>
                    </div>
                </event.ElementTag>
            )
        })


        hasViewMoreButton && (
            renderedEvents.push(
                <button
                    className="w-full text-left p-1 rounded-md text-tm-gray-500 relative cursor-pointer z-10 hover:text-primary hover:bg-tm-gray-100 z-10"
                    onClick={() => toggleShowMoreModal(day)}
                >
                    + {eventsNo - (maxNoVisible - 1)} more
                </button>
            )
        )

        return <div className="mt-1">{renderedEvents}</div>;
    }

    const getEventProps = (event) => {
        event.ElementTag = "button";
        event.Icon = TruckIcon;
        event.iconColor = "text-green-500"
        event.endDateIconColor = "text-yellow-500"
        event.ElementTag = Link;
        event.route = `/work-orders/${event.WorkOrderID}`

        return event;
    }

    const renderMonthlyViewMobileEvents = () => {
        return (
            <div className="flex space-x-1">
                <TruckIcon className="w-3 h-3 text-green-500"/>
            </div>
        )
    }

    const timeDateFormat = LocalStorage.get('user')?.Contact?.DateTimeFormat;
    const timeFormat = !!timeDateFormat && timeDateFormat.substring(timeDateFormat.indexOf(" ") + 1);

    const getEventTime = (event) => {
        if (!event?.Date) return null;

        if (event.isEndDateEvent) {
            return moment(event.EndDate, DEFAULT_DATABASE_DATETIME_FORMAT).format(timeFormat);
        }

        return moment(event.Date, DEFAULT_DATABASE_DATETIME_FORMAT).format(timeFormat);
    }

    const customQuery = (event, query) => {
        return (event?.StartDate && toFrontDateTime(event.StartDate.toLowerCase()).includes(query.toLowerCase())) ||
            (event?.EndDate && toFrontDateTime(event.EndDate.toLowerCase()).includes(query.toLowerCase()))
    }

    const modifyDays = (days) => {
        const endDateDays = {};

        days.forEach((day) => {
            if (day?.events?.length) {
                day?.events.forEach(event => {
                    if (!!event?.EndDate && event?.EndDate?.split(" ")[0] !== event?.StartDate?.split(" ")[0]) {
                        const endDate = event.EndDate.split(" ")[0];
                        if (!endDateDays[event.EndDate]) {
                            endDateDays[endDate] = {events: []}
                        }
                        const eventUpdate = Object.assign({}, event);
                        eventUpdate.isEndDateEvent = true;
                        endDateDays[endDate].events.push(eventUpdate);
                    }
                })
            }
        });


        days = days.map(day => {
            if (endDateDays[day.date]) {
                day.events = day.events.concat(endDateDays[day.date].events)
            }

            return day;
        })


        return days;
    }

    return (
        <Layout
            match={match}
            translate={translate}
            user={user}
            history={history}
            dispatch={dispatch}
        >
            <React.Fragment>
                <Calendar
                    onFetchData={fetchCalendarData}
                    events={events}
                    onDayClick={toggleShowMoreModal}
                    onTimeClick={() => null} // update when create button is defined
                    renderMonthlyViewEvents={renderMonthlyViewEvents}
                    renderMonthlyViewMobileEvents={renderMonthlyViewMobileEvents}
                    renderDayViewEvents={renderDayViewEvents}
                    translate={translate}
                    modifyDays={modifyDays}
                    addContainerClass={"max-h-[calc(100vh-19rem)]"}
                />

                <StackedListModal
                    open={isShowMoreModalOpen}
                    setOpen={toggleShowMoreModal}
                    list={selectedDayEvents}
                    customQuery={customQuery}
                    noDataMessage={translate("text.no_events_today")}
                    noDataQueryMessage={translate("text.no_events_found")}
                    getEvent={getEvent}
                />
            </React.Fragment>
        </Layout>
    )
}

const convertDate = (date, type) => {
    // Convert all except load time
    if (date) {
        return date
    } else return ""
}
const squashEvents = (events) => {
    let squashedEvents = {};
    return events.map((event, i) => {
        if (!!i && !!events[i - 1] && events[i].RoundDownMinutesDate === events[i - 1].RoundDownMinutesDate) {
            events[i - 1].hidden = true;

            if (!squashedEvents[events[i - 1].Type]) {
                squashedEvents[events[i - 1].Type] = [];
            }

            squashedEvents[events[i - 1].Type].push(events[i - 1]);

            if (!events[i + 1] || events[i].RoundDownMinutesDate !== events[i + 1].RoundDownMinutesDate) {
                if (!squashedEvents[events[i].Type]) {
                    squashedEvents[events[i].Type] = [];
                }

                squashedEvents[event.Type].push(events[i]);
                event.squashedEvents = squashedEvents;

                squashedEvents = {}; // reset
            }
        }

        return event;
    });
}


const roundDownMinutes = (date) => {
    let dateSplit = date.split(':');
    let minutes = dateSplit[1];
    minutes = Math.round(Number(minutes) / 15) * 15;
    dateSplit[1] = !minutes ? "00" : minutes.toString();
    date = dateSplit.join(":");

    return date;
}
