import ResourceTableDialog from "../resource-table-dialog";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../../data/services/resources";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Button from "../../button";
import {checkPerm} from "../../../../util/util";
import {CREATE_PERM, DELETE_PERM, UPDATE_PERM} from "../../../util/util-consts";
import React, {useState} from "react";
import useQuery from "../../../../hooks/use-query";
import {
    createDialogResource,
    deleteDialogResource,
    updateDialogResource
} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import {
    getPartCategoryFields,
    getPartCategoryQueryFields
} from "../../../../views/accounting/products-services/productsServiceCategoryTab";
import ModalSaveResource from "../modal-save-resource";
import ModalConfirm from "../modal-confirm";

export default function PartCategoryResourceList({
                                                     isLocationsListModalOpen,
                                                     onCloseLocationListModal,
                                                     onSelectItem,
                                                     translate
                                                 }) {
    const resourcePath = Resources.ProductsServicesCategory;

    const dispatch = useDispatch();

    const dialogResource = useSelector(state => state.dialogResource);

    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
    const [isPartCategoryModalOpen, setIsPartCategoryModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [partsPartCategoryFields] = useQuery(getPartCategoryQueryFields(), 'parts_category')
    const partCategoryQuery = FieldsManager.getFieldKeyValues(partsPartCategoryFields);

    function handleCreateCategoryClick(selectedItem = {}) {
        setIsPartCategoryModalOpen(true);
        if (!!selectedItem?.ProductServiceCategory) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }

        setSelectedItem(selectedItem)
    }

    function handleEditCategoryClick(item) {
        setIsPartCategoryModalOpen(true);
        setSelectedItem(item)
    }

    function handleDeleteCategoryClick(item) {
        setIsDeleteConfirmModalOpen(true);
        setSelectedItem(item);
    }

    function handleConfirmDeleteClick() {
        dispatch(deleteDialogResource({
            user: LocalStorage.get('user'),
            query: Object.assign({
                ProductServiceCategoryID: selectedItem.ProductServiceCategoryID
            }, partCategoryQuery),
            piggyQuery: partCategoryQuery,
            errorMessage: true,
            successMessage: translate(`message.archived_generic`, [selectedItem.ProductServiceCategoryID]),
            resource: resourcePath,
            piggyResource: resourcePath
        }));

        setIsDeleteConfirmModalOpen(false);
    }

    return (
        <>
            <ResourceTableDialog
                show={isLocationsListModalOpen}
                dialogResource={dialogResource}
                resource={resourcePath}
                title={translate("text.select_part_category")}
                noRecordsText={() => translate('text.no_matching_records')}
                noRecordsBtnLabel={(currentQuery) => translate('btn.create_part_category_name', [currentQuery])}
                noRecordsBtnClick={currentQuery => handleCreateCategoryClick({"ProductServiceCategory": currentQuery})}
                dispatch={dispatch}
                fields={getPartCategoryFields()}

                widthClass={"max-w-7xl"}
                options={{
                    style: {
                        stripedRows: true,
                        horizontalLines: false,
                        floatingActions: true,
                    },
                }}
                fieldsFilter={{
                    query: new Field('query', '', [''], false, 'search', {
                        addContainerClass: 'col-span-3',
                        labelType: "float"
                    })
                }}
                tableHeaderRight={
                    <div className="col-start-11 col-span-2 flex justify-end items-center">
                        <Button
                            hasPerm={checkPerm(resourcePath, CREATE_PERM)}
                            className="btn btn-primary"
                            onClick={handleCreateCategoryClick}
                        >
                            {translate("btn.create_part_category")}
                        </Button>
                    </div>
                }
                sortBy={"ProductServiceCategory"}
                defaultAction={(item) => onSelectItem(item)}
                onEdit={(item) => handleEditCategoryClick(item)}
                hasEditPerm={checkPerm(resourcePath, UPDATE_PERM)}
                onDelete={(item) => handleDeleteCategoryClick(item)}
                hasDeletePerm={checkPerm(resourcePath, DELETE_PERM)}
                onClose={onCloseLocationListModal}
                translate={translate}
                htmlAfter={
                    <>
                        <ModalSaveResource
                            title={translate(`text.${selectedItem?.ProductServiceCategoryID ? 'Edit' : 'CreateNew'}ServiceCategory`)}
                            show={isPartCategoryModalOpen}
                            widthClass="max-w-md"
                            gridColsClass="grid-cols-3"
                            translate={translate}
                            fields={getPartCategoryFields(selectedItem)}
                            canSubmit={!isButtonDisabled}
                            onClose={() => setIsPartCategoryModalOpen(false)}
                            onSubmit={(params) => {
                                if (params) {
                                    if (selectedItem.ProductServiceCategoryID) {
                                        dispatch(updateDialogResource({
                                            user: LocalStorage.get('user'),
                                            params: Object.assign(params, {
                                                ProductServiceCategoryID: selectedItem.ProductServiceCategoryID
                                            }),
                                            errorMessage: true, successMessage: `Category has been created.`,
                                            resource: resourcePath,
                                            piggyResource: resourcePath
                                        }))
                                    } else {
                                        dispatch(createDialogResource({
                                            user: LocalStorage.get('user'),
                                            params: Object.assign(params, {
                                                query: partCategoryQuery
                                            }),
                                            errorMessage: true, successMessage: `Category has been created.`,
                                            resource: resourcePath,
                                            piggyResource: resourcePath
                                        }))
                                    }

                                    setIsPartCategoryModalOpen(false);
                                }
                            }}
                        />

                        <ModalConfirm
                            title={translate("text.Confirm")}
                            show={isDeleteConfirmModalOpen}
                            text={translate('message.confirm_archive_generic') + " " + selectedItem.ProductServiceCategory + "?"}
                            onClose={() => {
                                setIsDeleteConfirmModalOpen(false);
                            }}
                            buttonLabel={translate("btn.confirm")}
                            closeButtonLabel={'Cancel'}
                            translate={translate}
                            onConfirm={handleConfirmDeleteClick}
                        />
                    </>
                }
            />
        </>
    )
}