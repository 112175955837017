import React, {useState} from "react";
import Layout from "../../common/components/layout";
import PageHeader from "../../common/components/layout/layout-components/page/page-header";
import Page from "../../common/components/layout/layout-components/page";
import PartsTab from "./parts-tab";
import NavResponsive from "../../common/components/nav-responsive";
import {useDispatch, useSelector} from "react-redux";
import ProductsServicesCategoryTab from "../accounting/products-services/productsServiceCategoryTab";
import Resources from "../../data/services/resources";
import PartsReportTab from "./reports-tab";
import {checkPerm} from "../../common/util/util-helpers";
import {READ_PERM} from "../../common/util/util-consts";
import PartLocations from "./part-locations";
import PartManufacturers from "./manufacturers-tab";

export default function PartsView({history, match, translate, location}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector(state => state.resource);
    const thirdResource = useSelector(state => state.thirdResource);
    const dialogResource = useSelector(state => state.dialogResource);

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(getTabs());
    const selectedTab = tabs.find(it => !!it.current);

    const [breakPoint, setBreakPoint] = useState({});


    /** UI events
     ================================================================= */
    function handleTabChange(resource) {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource
                return it
            })
        )
    }

    function handleBreakpointChange(breakpoint) {
        setBreakPoint(breakpoint);
    }

    /** Helpers
     ================================================================= */
    function getTabs() {
        return [
            {
                name: 'Parts',
                resource: "parts",
                current: true,
                visible: true,
                hasPerm: checkPerm(Resources.WorkOrderProductsServices, READ_PERM)
            },
            {
                name: 'partCategories',
                resource: Resources.WorkOrderProductsServicesCategory,
                current: false,
                visible: true,
                hasPerm: checkPerm(Resources.WorkOrderProductsServicesCategory, READ_PERM)
            },
            {
                name: 'partLocations',
                resource: Resources.WorkOrderPartLocations,
                current: false,
                hasPerm: checkPerm(Resources.WorkOrderPartLocations, READ_PERM)
            },
            {
                name: 'partManufacturers',
                resource: Resources.WorkOrderProductsManufacturers,
                current: false,
                hasPerm: checkPerm(Resources.WorkOrderProductsManufacturers, READ_PERM)
            },
            {
                name: 'partReports',
                resource: "report",
                current: false,
                visible: true,
                hasPerm: checkPerm(Resources.WorkOrderProductServicesReport, READ_PERM)
            }
        ]
    }

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <Layout
                onBreakpointChange={() => {

                }}
                history={history}
                match={match}
                translate={translate}
            >
                <Page>
                    <PageHeader
                        title={translate("page_title.parts")}
                    />

                    <NavResponsive
                        tabs={tabs}
                        onTabChange={handleTabChange}
                        translate={translate}
                    />

                    {selectedTab.name === 'Parts' && (
                        <PartsTab
                            history={history}
                            match={match}
                            translate={translate}
                        />
                    )}

                    {selectedTab.name === 'partCategories' && (
                        <ProductsServicesCategoryTab
                            resourcePath={Resources.WorkOrderProductsServicesCategory}
                            history={history}
                            match={match}
                            translate={translate}
                            dispatch={dispatch}
                            resource={resource}
                            thirdResource={thirdResource}
                            dialogResource={dialogResource}
                            location={location}
                            breakpoint={breakPoint}
                        />
                    )}

                    {selectedTab.name === 'partLocations' && (
                        <div className={"mt-4"}>
                            <PartLocations
                                history={history}
                                match={match}
                                translate={translate}
                            />
                        </div>
                    )}

                    {selectedTab.name === 'partManufacturers' && (
                        <div className={"mt-4"}>
                            <PartManufacturers
                                history={history}
                                match={match}
                                translate={translate}
                            />
                        </div>
                    )}

                    {selectedTab.name === 'partReports' && (
                        <PartsReportTab
                            history={history}
                            match={match}
                            translate={translate}
                        />
                    )}
                </Page>
            </Layout>
        </React.Fragment>
    )
}
