import React, {useEffect, useState} from "react";
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import {LoaderSmall} from "../../../common/components/loader";
import {classNames} from "../../../common/util/util-helpers";

export default function WorkOrdersLaborPartsPrices(
    {
        isResourceLoading,
        isSecondResourceLoading,
        totalPriceParts,
        totalPriceLabor,
        shopSupplies,
        dataObject,
        dataMiscTotal,
        isItem
    }
) {
    const [surchargeAmount, setSurchargeAmount] = useState(dataObject?.SurchargeAmount)

    useEffect(() => {
        setSurchargeAmount(null);
    }, [totalPriceLabor, totalPriceParts]);

    useEffect(() => {
        setSurchargeAmount(dataObject?.SurchargeAmount)
    }, [dataObject]);

    const SurchargeType = (type) => {

        switch (type) {
            case 1:
                return "No surcharge"
            case 2:
                return "Labor only"
            case 3:
                return "Labor and parts"
            default:
                return "No surcharge"
        }
    }
    return (
        <div
            className={classNames("grid grid-cols-1 divide-y divide-tm-gray-200 border border-tm-gray-200 bg-inverse sm:divide-y-0 sm:divide-x rounded-lg shadow-card h-16", isItem ? "sm:grid-cols-3" : "sm:grid-cols-4")}
        >
            <div className="px-6 py-1 text-center text-base font-medium text-tm-gray-700">
                {!isResourceLoading && (
                    <React.Fragment>
                        <p>Parts: {" "}</p>
                        <span
                            className="font-semibold tracking-wide text-tm-gray-900"
                        >
                                    {genericMoneyFormatter(totalPriceParts)}
                                </span>
                    </React.Fragment>
                )}

                {isResourceLoading && (
                    <div className="flex w-full justify-center">
                        <LoaderSmall disableContainer/>
                    </div>
                )}
            </div>

            <div className="px-6 py-1 text-center text-base font-medium text-tm-gray-700">
                {!isSecondResourceLoading && (
                    <React.Fragment>
                        <p>Labor: {" "}</p>
                        <span
                            className="font-semibold tracking-wide text-tm-gray-900"
                        >
                                    {genericMoneyFormatter(totalPriceLabor)}
                                </span>
                    </React.Fragment>
                )}

                {isSecondResourceLoading && (
                    <div className="flex w-full justify-center">
                        <LoaderSmall disableContainer/>
                    </div>
                )}
            </div>

            {!!dataObject && (
                <div className="px-6 py-1 text-center text-base font-medium text-tm-gray-700">
                    {!isResourceLoading && !isSecondResourceLoading && (
                        <React.Fragment>
                            <p>Misc. charges: {" "}</p>
                            <span
                                className="font-semibold tracking-wide text-tm-gray-900"
                            >
                                    {genericMoneyFormatter(dataMiscTotal)}
                                </span>
                        </React.Fragment>
                    )}

                    {(isResourceLoading || isResourceLoading) && (
                        <div className="flex w-full justify-center">
                            <LoaderSmall disableContainer/>
                        </div>
                    )}
                </div>
            )}

            {!shopSupplies && (
                <div className="px-6 py-1 text-center text-lg font-bold text-tm-gray-700">
                    {!isResourceLoading && !isSecondResourceLoading && (
                        <React.Fragment>
                            <p>Total: {" "}</p>
                            <span
                                className="font-semibold tracking-wide text-tm-gray-900"
                            >
                                {!!isItem && (
                                    genericMoneyFormatter(totalPriceLabor + totalPriceParts)
                                )}

                                {!isItem && (
                                    genericMoneyFormatter(totalPriceLabor + totalPriceParts + Number(dataMiscTotal))
                                )}
                                </span>
                        </React.Fragment>
                    )}

                    {(isResourceLoading || isResourceLoading) && (
                        <div className="flex w-full justify-center">
                            <LoaderSmall disableContainer/>
                        </div>
                    )}
                </div>
            )}
        </div>

    )
}