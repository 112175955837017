import React, {useEffect, useState} from "react";
import Layout from "../../common/components/layout";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import {getDefaultQueryFields, getUser} from "../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import useQuery from "../../hooks/use-query";
import {checkPerm, getLookup, getProp} from "../../common/util/util-helpers";
import LocalStorage from "../../util/localStorage";
import ResourceList from "../../common/components/layout/layout-components/resource-list";
import ModalConfirm from "../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../common/components/modal/modal-save-resource";
import {fillFieldsFromData} from "../../common/util/util-fields";
import PageHeader from "../../common/components/layout/layout-components/page/page-header";
import Page from "../../common/components/layout/layout-components/page";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, DELETE_PERM, UPDATE_PERM} from "../../common/util/util-consts";
import {DEFAULT_LIMIT, DEFAULT_OFFSET, DEFAULT_SORT} from "../../util/util-constants";
import {genericMoneyFormatter} from "../../common/util/util-vanilla";
import Tippy from "@tippyjs/react";

export default function ServiceBaysView({history, match, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath());
    const {
        BayType,
        PrimaryTechnicianID
    } = FieldsManager.getFieldKeyValues(queryFields)

    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), {searchFields: JSON.stringify({BayType: BayType, PrimaryTechnicianID: PrimaryTechnicianID}) });

    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [confirmDialogData, setConfirmDialogData] = useState({});

    /** UI events
     ================================================================= */

    useEffect(() => {
        fetchData(dispatch, query);
    }, [queryFields]);

    const handleEditResourceClick = (item) => {
        setIsResourceDialogOpen(true);
        setSelectedItem(item);
    }

    const handleCreateResourceClick = () => {
        setIsResourceDialogOpen(true);
        setSelectedItem({});
    }

    const handleDeleteServiceClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteServiceClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to delete service bay <span className="font-bold text-red-600">{item.BayNumber}</span>?
            </span>,
            title: translate("text.confirm_delete_service_bay"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeleteServiceClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign(query,{ServiceBayID: item.ServiceBayID}),
            notificationMessage: translate(`text.service_bay_deleted`),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            successMessage: translate("message.service_bay_delete"),
            errorMessage: true
        }));

        closeConfirmDialog();
    }

    const handleRestoreResourceClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmRestoreResourceClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to restore this item?
            </span>,
            title: translate("text.confirm_restore_service_bay", [item.ServiceBayID]),
            buttonLabel: translate("btn.restore")
        })
    }

    const handleConfirmRestoreResourceClick = (item) => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {ServiceBayID: item.ServiceBayID, ArchivedDate: 1},
            query: query,
            notificationMessage: translate(`text.service_bay_restored`),
            resource: getResourcePath(),
            piggyResource: getResourcePath()
        }));

        closeConfirmDialog();
    }
    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    console.log("selectedItem",selectedItem)
    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <Layout
                history={history}
                match={match}
                translate={translate}
            >
                <Page>
                    <PageHeader
                        title={translate("page_title.ServiceBays")}
                        buttons={[
                            {
                                label: translate("btn.create"),
                                type: "primary",
                                onClick: handleCreateResourceClick
                            }
                        ]}
                    />

                    <ResourceList
                        fields={getFields({})}

                        resource={resource}
                        isLoading={isLoading}
                        queryFields={queryFields}
                        setQueryFields={setQueryFields}
                        listPath={getListPath()}
                        onRefreshTable={() => fetchData(dispatch, query)}
                        dispatch={dispatch}
                        query={query}

                        translate={translate}
                        selects={getSelects()}

                        onRowClick={handleEditResourceClick}
                        onEdit={handleEditResourceClick}
                        onDelete={handleDeleteServiceClick}
                        onRestore={handleRestoreResourceClick}

                        hasDeletePerm={checkPerm(Resources.ServiceBays, DELETE_PERM)}
                        hasEditPerm={checkPerm(Resources.ServiceBays, UPDATE_PERM)}

                    />

                <ModalConfirm
                    show={confirmDialogData?.isOpen ?? false}
                    type={confirmDialogData?.type}
                    title={confirmDialogData?.title}
                    onConfirm={confirmDialogData?.onClick}
                    closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                    buttonLabel={confirmDialogData?.buttonLabel}
                    translate={translate}
                    onClose={closeConfirmDialog}
                >
                    {confirmDialogData?.content}
                </ModalConfirm>

                <ModalSaveResource
                    show={isResourceDialogOpen}
                    fields={getFields(selectedItem)}
                    //onInputChange={handleInputChange}
                    title={!!selectedItem?.BayNumber ? translate("modal_heading.edit_item") : translate("modal_heading.create_item")}
                    onClose={() => {
                        setIsResourceDialogOpen(false);
                        setSelectedItem({})
                    }}
                    onSubmit={(params) => {

                        if (!!params) {
                            if (!!selectedItem.ServiceBayID) {
                                params.ServiceBayID = selectedItem.ServiceBayID;
                                dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: query,
                                    resource: getResourcePath(),
                                    piggyResource: getResourcePath(),
                                    successMessage: translate("message.service_bay_item_update"),
                                    errorMessage: true
                                }));
                            } else {
                                dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: query,
                                    resource: getResourcePath(),
                                    piggyResource: getResourcePath(),
                                    successMessage: translate("message.service_bay_item_create"),
                                    errorMessage: true
                                }));
                            }

                            setSelectedItem(params); // in case of server error save form data
                            setIsResourceDialogOpen(false)

                        }
                    }}
                    addFieldContainerClass={"col-span-full"}
                    addBodyClass={"pb-48 text-tm-gray-900"}
                    isLoading={isLoading}
                    metadata={getSelects()}
                    resource={resource}
                    translate={translate}
                />
                </Page>
            </Layout>
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getPrimaryField = () => "BayNumber";
const getResourcePath = () => Resources.ServiceBays;

const getListPath = () => getResourcePath() + "_list"

const fetchData = (dispatch, query) => {
    dispatch(getResource({
        user: getUser(),
        resource: getResourcePath(),
        query: query,

    }))

}

const getQueryFields = (translate) => {
    return (
        {
            query: new Field('query', '', [''], false, 'search', {
                hideLabel: true,
                hasActiveBadge: true,
                labelType: "float"
            }, {placeholder: translate("text.search")}),
            archived: new Field('archived', '', [''], false, 'checkbox', {
                labelType: "float",
                label: "archived",
                hasActiveBadge: true,
            }),
            sort: new Field('sort', DEFAULT_SORT, [''], false, 'hidden'),
            sortBy: new Field('sortBy', getPrimaryField(), [''], false, 'hidden'),
            offset: new Field('offset', DEFAULT_OFFSET, [''], false, 'hidden'),
            limit: new Field('limit', DEFAULT_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {
                hasPortal: true,
                placeholder: "100",
                menuPlacement: "top"
            }),
            BayType: new Field('BayType', '', [''], false, 'select', {
                hasActiveBadge: true,
            }, {isClearable: true}),
            PrimaryTechnicianID: new Field('PrimaryTechnicianID', '', [''], false, 'select-search', {
                hasActiveBadge: true,
            },{isClearable: true})
        }
    )
}

export function getFields(item = {}) {
    const fieldTemplates = {
        BayNumber: new Field('BayNumber', '', ['empty'], false, 'text', {addContainerClass: "col-span-full"}),
        BayType: new Field('BayType', '', ['empty'], false, 'select', {addContainerClass: "col-span-full",
          render:(it) => {
            return (
                      <Tippy
                          content={
                              getLookup("BayType")[it.BayType]
                          }
                          placement={"left"}
                      >
                          <div className={"text-left"}>
                              {getLookup("BayType")[it.BayType].split(':')[0]}
                          </div>
                      </Tippy>
            )
          }
        }, {
            values: getLookup("BayType")
        }),
        PrimaryTechnicianID: new Field('PrimaryTechnicianID', '', [], false, 'select-search', {addContainerClass: "col-span-full"}, {isClearable: true}),
        Notes: new Field('Notes', '', [''], false, "textarea", {addContainerClass: "col-span-full"}),


    }

    return fillFieldsFromData(fieldTemplates, item)
}


export function getSelects() {
    return {
        PrimaryTechnicianID: {
            api: 'api/' + Resources.ContactsQuick,
            query: {
                limit: 20,
                offset: 0,
                IsTechnician: 1
            },
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName
            })
        },
        BayType: getLookup("BayType")
    }
}
