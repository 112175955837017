import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../data/services/resources";
import {READ_PERM, UPDATE_PERM} from "../../util/util-constants";
import PageHeader from "../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../common/components/nav-responsive";
import Layout from "../../common/components/layout";
import {checkPerm} from "../../common/util/util-helpers";
import RepairCodesView from "./repair-codes-view";
import WorkOrderSettingsTab from "./settings-general-tab";
import DocumentTypesTab from "../../common/components/tabs/document-types-tab";
import Page from "../../common/components/layout/layout-components/page";


export default function WorkOrderSettingsView({translate, match, history, location}) {
    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);

    const [tabs, setTabs] = useState(getTabs());
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const currentTab = tabs.find(tab => tab.current);

    return (
        <Layout
            ui={ui}
            match={match}
            history={history}
            location={location}
            translate={translate}
            isAccessible={checkPerm(Resources.WorkOrderSettings, UPDATE_PERM)}
            dispatch={dispatch}
            hasFooter={currentTab.name === 'general'}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.work_order_settings')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {currentTab.name === 'general' && (
                    <div className="grid gap-4 py-8 max-w-3xl mx-auto">
                        <WorkOrderSettingsTab
                            translate={translate}
                        />
                    </div>
                )}

                {currentTab.name === "DocumentTypes" && (
                    <DocumentTypesTab
                        translate={translate}
                        primaryType={'IsWorkOrderDocument'}
                    />
                )}

                {currentTab.name === 'repairCodes' && (
                    <div className={"mt-4"}>
                        <RepairCodesView
                            history={history}
                            match={match}
                            translate={translate}
                        />
                    </div>
                )}
            </Page>
        </Layout>
    )
}

const getTabs = () => {
    return [
        {
            name: 'general',
            resource: Resources.WorkOrderSettings,
            current: true,
            visible: checkPerm(Resources.WorkOrderSettings, READ_PERM)
        },
        {
            name: 'DocumentTypes',
            resource: Resources.DocumentTypes,
            current: false,
            visible: checkPerm(Resources.DocumentTypes, READ_PERM)
        },
        {
            name: 'repairCodes',
            resource: Resources.WorkOrdersCustomCodes,
            current: false,
            visible: checkPerm(Resources.WorkOrdersCustomCodes, READ_PERM)
        }
    ]
}
