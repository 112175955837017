import ResourceTableDialog from "../resource-table-dialog";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../../data/services/resources";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Button from "../../button";
import {checkPerm} from "../../../../util/util";
import {CREATE_PERM, DELETE_PERM, UPDATE_PERM} from "../../../util/util-consts";
import {getPartLocationsFields, getPartLocationsQueryFields} from "../../../../views/parts-view/part-locations";
import PartLocationsModal from "../../../../views/parts-view/part-locations/part-locations-modal";
import React, {useState} from "react";
import useQuery from "../../../../hooks/use-query";
import {
    createDialogResource,
    deleteDialogResource,
    updateDialogResource
} from "../../../../data/actions/dialogResource";
import ModalConfirm from "../modal-confirm";
import LocalStorage from "../../../../util/localStorage";

export default function PartLocationsResourceList({isLocationsListModalOpen, onCloseLocationListModal, onSelectItem, translate}) {
    const dispatch = useDispatch();

    const dialogResource = useSelector(state => state.dialogResource);

    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
    const [isPartLocationModalOpen, setIsPartLocationModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [partsPartLocationsFields] = useQuery(getPartLocationsQueryFields(translate), 'part-locations-tab')
    const partLocationsQuery = FieldsManager.getFieldKeyValues(partsPartLocationsFields);

    function handleCreateLocationClick(selectedItem = {}) {
        setIsPartLocationModalOpen(true);

        if (!!selectedItem?.ProductServiceLocation) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }

        setSelectedItem(selectedItem)
    }

    function handleEditLocationClick(item) {
        setIsPartLocationModalOpen(true);
        setSelectedItem(item)
    }

    function handleDeleteLocationClick(item) {
        setIsDeleteConfirmModalOpen(true);
        setSelectedItem(item);
    }

    function handleConfirmDeleteClick() {
        dispatch(deleteDialogResource({
            user: LocalStorage.get('user'),
            query: {
                ProductServiceLocationID: selectedItem.ProductServiceLocationID
            },
            piggyQuery: partLocationsQuery,
            errorMessage: true,
            successMessage: translate(`message.archived_generic`, [selectedItem.ProductServiceLocation]),
            resource: Resources.WorkOrderPartLocations,
            piggyResource: Resources.WorkOrderPartLocations
        }));

        setIsDeleteConfirmModalOpen(false);
    }

    function getPartLocationsResourcePath() {
        return Resources.WorkOrderPartLocations;
    }

    return (
        <>
            <ResourceTableDialog
                show={isLocationsListModalOpen}
                dialogResource={dialogResource}
                resource={Resources.WorkOrderPartLocations}
                title={translate("text.select_part_location")}

                noRecordsText={() => translate('text.no_matching_records')}
                noRecordsBtnLabel={(currentQuery) => translate('btn.create_part_location_name', [currentQuery])}
                noRecordsBtnClick={currentQuery => handleCreateLocationClick({"ProductServiceLocation": currentQuery})}
                dispatch={dispatch}
                fields={getPartLocationsFields()}

                widthClass={"max-w-7xl"}
                options={{
                    style: {
                        stripedRows: true,
                        horizontalLines: false,
                        floatingActions: true,
                    },
                }}
                fieldsFilter={{
                    query: new Field('query', '', [''], false, 'search', {
                        addContainerClass: 'col-span-3',
                        labelType: "float"
                    })
                }}
                tableHeaderRight={
                    <div className="col-start-11 col-span-2 flex justify-end items-center">
                        <Button
                            hasPerm={checkPerm(Resources.WorkOrderPartLocations, CREATE_PERM)}
                            className="btn btn-primary"
                            onClick={handleCreateLocationClick}
                        >
                            {translate("btn.create_location")}
                        </Button>
                    </div>
                }
                sortBy={"ProductServiceLocation"}
                defaultAction={(item) => onSelectItem(item)}
                onEdit={(item) => handleEditLocationClick(item)}
                hasEditPerm={checkPerm(Resources.WorkOrderPartLocations, UPDATE_PERM)}
                onDelete={(item) => handleDeleteLocationClick(item)}
                hasDeletePerm={checkPerm(Resources.WorkOrderPartLocations, DELETE_PERM)}
                onClose={onCloseLocationListModal}
                translate={translate}
                htmlAfter={
                    <>
                        <PartLocationsModal
                            show={isPartLocationModalOpen}
                            canSubmit={!isButtonDisabled}
                            onClose={() => setIsPartLocationModalOpen(false)}
                            selectedItem={selectedItem}
                            query={partLocationsQuery}
                            getResourcePath={() => getPartLocationsResourcePath()}
                            translate={translate}
                            dispatchCreateResource={createDialogResource}
                            dispatchUpdateResource={updateDialogResource}
                        />

                        <ModalConfirm
                            title={translate("text.Confirm")}
                            show={isDeleteConfirmModalOpen}
                            text={"Are you sure you want do delete part location?"}
                            onClose={() => {
                                setIsDeleteConfirmModalOpen(false);
                            }}
                            buttonLabel={translate("btn.confirm")}
                            closeButtonLabel={'Cancel'}
                            translate={translate}
                            onConfirm={handleConfirmDeleteClick}
                        />
                    </>
                }
            />
        </>
    )
}