import Bot from "../../../../../assets/images/bot.png";
import ChatbotBox from "../../../../../common/components/layout/layout-components/AIChat/chatbot-box";
import React, {useState} from "react";
import FieldTextarea from "../../../../../common/components/fields/field-textarea";
import Button from "../../../../../common/components/button";
import axios from "axios";
import Env from "../../../../../util/env";
import {getJWT} from "../../../../../common/util/util-auth";
import CopyToClipboardButton from "../../../../../common/components/buttons/copy-to-clipboard-button";
import {processResponse} from "../../../../../data/services/api-util";

export default function ErrorCodeHelpAiModal({translate, dataUnit}) {
    const [input, setInput] = useState("");
    const [prevInput, setPrevInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [aiData, setAiData] = useState();

    const handleAiHelpClick = () => {
        setIsLoading(true)
        getAiHelp().then((res) => {
            setAiData(processResponse(res)?.data);
            setIsLoading(false)
            setPrevInput(input);
            setInput("");
        });
    }

    const getAiHelp = () => {
        return axios.get(
            Env.getWorkOrderAiUrl('error', {
                input: input ?? "",
                make: dataUnit?.Manufacturer,
                model: dataUnit?.Model,
                year: dataUnit?.Year,
                type: dataUnit?.Type,
            }),
            {
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                },
            }
        )
    }

    return (
        <div className="p-6 space-y-6">
            <div
                className="text-base flex rounded-btn items-center gap-4 bg-tm-gray-100 border-2 border-tm-gray-200 px-2 py-1">
                <div
                    className='w-12 h-12 bg-tm-gray-50 border-2 border-tm-gray-300 p-2 rounded-full text-tm-gray-700 flex-shrink-0'>
                    <img src={Bot} alt="bot"/>
                </div>

                <ChatbotBox
                    fakeStreamSpeed={10}
                    streamContent={true}
                    content={"Enter one or more codes on your vehicle, and I will try to interpret them as best I can."}
                />
            </div>

            <FieldTextarea
                disabled={isLoading}
                addClass="form-control pt-2"
                rows="3"
                value={input ?? ""}
                name={"input"}
                placeholder="Enter error code here..."
                onChange={(_, value) => setInput(value)}
            />

            <div className="flex justify-center">
                <Button
                    onClick={handleAiHelpClick}
                    isLoading={isLoading}
                    disabled={!input}
                    appearance="primary"
                >
                    Describe code
                </Button>
            </div>

            {!isLoading && prevInput && !!aiData?.Codes.length && (
                <div className="pt-10">
                    <div className="flex justify-end">
                        <div
                            className="text-base flex rounded-btn items-center gap-4 bg-tm-gray-100 border-2 border-tm-gray-200 px-2 py-1">
                            {prevInput}
                        </div>
                    </div>

                    <div
                        className="text-base flex rounded-btn items-center gap-4 px-2 py-1">
                        <div
                            className='w-12 h-12 bg-tm-gray-50 border-2 border-tm-gray-300 p-2 rounded-full text-tm-gray-700 flex-shrink-0'>
                            <img src={Bot} alt="bot"/>
                        </div>
                        <ChatbotBox
                            fakeStreamSpeed={10}
                            streamContent={true}
                            content={"Here are the results of your query:"}
                        />
                    </div>
                </div>
            )}

            <div className="space-y-6">
                {!isLoading && !!aiData?.Codes && aiData.Codes.map(it => {
                    return <dl className="divide-y divide-tm-gray-300 border border-tm-gray-300 px-10 rounded-btn">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-tm-gray-900">Code</dt>
                            <dd className="mt-1 text-sm leading-6 text-tm-gray-700 sm:col-span-3 sm:mt-0">
                                {it.CodeTitle}

                                {
                                    it.CodeTitle
                                        ? <span className="relative">
                                            <CopyToClipboardButton
                                                className={"absolute w-6 h-6 -translate-y-1.5 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-link text-tm-gray-600 hover:text-tm-gray-900 group ml-2.5"}
                                                clipboardText={it.CodeTitle}
                                                translate={translate}
                                            />
                                        </span>
                                        : undefined
                                }
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-tm-gray-900"> Code description</dt>
                            <dd className="mt-1 text-sm leading-6 text-tm-gray-700 sm:col-span-3 sm:mt-0">
                                {it.CodeDescription}

                                {
                                    it.CodeDescription
                                        ? <span className="relative">
                                            <CopyToClipboardButton
                                                className={"absolute w-6 h-6 -translate-y-1.5 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-link text-tm-gray-600 hover:text-tm-gray-900 group ml-2.5"}
                                                clipboardText={it.CodeDescription}
                                                translate={translate}
                                            />
                                        </span>
                                        : undefined
                                }
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-tm-gray-900"> Suggested action</dt>
                            <dd className="mt-1 text-sm leading-6  text-tm-gray-700 sm:col-span-3 sm:mt-0">
                                {it.SuggestedAction}

                                {
                                    it.SuggestedAction
                                        ? <span className="relative">
                                                <CopyToClipboardButton
                                                    className={"absolute w-6 h-6 -translate-y-1.5 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-link text-tm-gray-600 hover:text-tm-gray-900 group ml-2.5"}
                                                    clipboardText={it.SuggestedAction}
                                                    translate={translate}
                                                />
                                        </span>
                                        : undefined
                                }
                            </dd>
                        </div>
                    </dl>
                })}

            </div>
        </div>
    )
}