import React, {useState} from "react";
import WorkOrdersLaborPartsTemplateEdit from "./index";
import {useDispatch} from "react-redux";
import {getUser} from "../../../common/util/util-auth";
import Layout from "../../../common/components/layout";
import Resources from "../../../data/services/resources";
import NavResponsive from "../../../common/components/nav-responsive";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";

export default function WoTemplateSingle({history, match, translate}) {
    const ID = match.params.id;
    const user = getUser();
    const dispatch = useDispatch();
    const [tabs, setTabs] = useState(getTabs());
    const currentTab = tabs.find(it => it.current);

    function getTabs() {
        return [
            {
                name: 'WorkOrdersTemplateEdit',
                resource: Resources.WorkOrderTemplates,
                current: true,
                visible: true
            }
        ]
    }

    return (
        <Layout
            match={match}
            translate={translate}
            user={user}
            history={history}
            dispatch={dispatch}
        >
            <React.Fragment>
                <PageHeader
                    title={"Work order template" + " - " + (ID ?? '')}
                    removeBorder={true}
                    ButtonsLeading={[
                        {
                            iconLeading: ArrowLeftIcon,
                            className: "btn btn-header m-2",
                            onClick: () => history.push('/work-orders-templates/'),
                            title: "Go back to work orders templates list"
                        }
                    ]}
                />

                <NavResponsive
                    addClass="px-4 sm:px-6 md:px-8 h-10 mb-2"
                    tabs={tabs}
                    onTabChange={() => {
                    }}
                    translate={translate}
                />

                <WorkOrdersLaborPartsTemplateEdit
                    ID={ID}
                    dispatch={dispatch}
                    translate={translate}
                />
            </React.Fragment>
        </Layout>
    )
}

