import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CREATE_PERM, DEFAULT_CRUD_STATE, DEFAULT_QUERY_LIMIT, DELETE_PERM} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    resourceIsCreated,
    resourceIsUpdated,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {showGlobalModal} from '../../../data/actions/ui'
import {MagnifyingGlassIcon, TrashIcon} from '@heroicons/react/24/outline'
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Button from '../../../common/components/button'
import {getDialogResource, resetDialogResource} from "../../../data/actions/dialogResource";
import VendorCreditModal from "./vendor-credit-modal";
import {READ_PERM, UPDATE_PERM} from "../../../common/util/util-consts";
import ModalDefault from "../../../common/components/modal/modal-default";
import Env from "../../../util/env";
import {getJWT} from "../../../common/util/util-auth";
import FileViewer from "../../../common/components/file-viewer/components";

class VendorCreditTab extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_vendor_credit'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            createUpdateDialog: false,
            confirmModalOpen: false,
            deleteDialog: false,
            isTableFullScreen: false,

            isVendorCreditModalOpen: false,
            PreviewDialog: false,

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
            selectedItem: undefined
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }


        if (resourceIsCreated(this?.props?.resource, prevProps.resource)) {
            this.handleToggleCreateModal();
        }

        if (resourceIsUpdated(this?.props?.resource, prevProps.resource)) {
            this.handleToggleEditModal();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign(
                {format: 'EXCEL', name: 'Recurring_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = ''
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }
    handleCreateUpdateResource = (item = null, prefilled = '') => {
        this.setState({prefilled: prefilled}, () => {
            this.props.dispatch(showGlobalModal('createTaskDialog', item))
        })
    }

    handleToggleEditModal = (selectedItem = null) => {
        this.setState({
            selectedItem: selectedItem,
            isVendorCreditModalOpen: !this.state.isVendorCreditModalOpen,
            isCreatingFromTemplate: false
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            isVendorCreditModalOpen: !this.state.isVendorCreditModalOpen,
            selectedItem: undefined
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    toggleProcessPreview = (item = null) => {
        this.setState({
            previewDocument: item,
            PreviewDialog: !this.state.PreviewDialog
        })
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input', 'showArchived'])
    }

    getQuery = () => {
        const queryFields = Object.assign({}, this.state.queryFilterFields);

        let query = {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(queryFields),

            searchFields: JSON.stringify({
                OrganizationID: queryFields.OrganizationID?.value?.value
            })
        }

        delete query.OrganizationID;

        return query;
    }

    getFields(item = {}) {
        const fieldTemplates = {
            Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            Date: new Field('Date', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            Amount: new Field('Amount', '', [''], false, 'money', {addContainerClass: 'col-span-3'}),
            VendorCreditStatusID: new Field('VendorCreditStatusID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                hideDialog: true
            }, {
                values: {0: "Pending", 1: "Attached to bill", 2: "Cash payout"}
            }),

        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'}),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {}, {isClearable: true}),
        }
    }

    getResource = () => {
        return Resources.ExpenseVendorCredit
    }

    handleToggleDeleteDialog = (submit, item) => {
        this.setState({
            confirmText: this.props.translate('message.confirm_delete_generic', [item?.Description]),
            deleteDialog: submit
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleDownloadDocument = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.ExpenseVendorCreditDocuments,
            query: Object.assign({}, {
                VendorCreditID: this.state.previewDocument?.VendorCreditID,
                name: this.state.previewDocument?.AttachPath
            }),
            notificationMessage: 'Successfully downloaded document!'
        }))
    }

    deleteResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_delete_generic', [item.Description])} ?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {
                            VendorCreditID: item.VendorCreditID
                        }),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(`text.delete_vendor_credit`),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    handleCreateFromTemplateClick = (item) => {
        this.setState({
            selectedItem: item,
            isVendorCreditModalOpen: !this.state.isVendorCreditModalOpen
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, dialogResource} = this.props

        const isLoading = !!resource.isLoading
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <React.Fragment>
                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="flex ml-auto">
                        <div className={'flex mr-4'}>
                            {/*<Tippy content={translate('text.download_excel')}>*/}
                            {/*    <button*/}
                            {/*        className="btn-icon"*/}
                            {/*        onClick={this.downloadExcel}*/}
                            {/*    >*/}
                            {/*        <DocumentArrowDownIcon className="w-5 h-5"/>*/}
                            {/*    </button>*/}
                            {/*</Tippy>*/}

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>

                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.handleToggleCreateModal()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={isLoading}
                    />

                    <ResourceTable
                        tableKey={'VendorCreditID'}
                        data={data}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        renderCells={this.renderCells}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        onEdit={(it) => it.VendorCreditStatusID === 0 && this.handleToggleEditModal(it)}
                        onRowClick={(it) => it.VendorCreditStatusID === 0 && checkPerm(this.getResource(), UPDATE_PERM) && this.handleToggleEditModal(it)}
                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}
                        hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}

                        saveTableOptions={this.setOptions}

                        actions={[
                            {
                                action: this.toggleProcessPreview,
                                icon: MagnifyingGlassIcon,
                                hasPerm: checkPerm(Resources.ExpenseVendorCreditDocuments, READ_PERM),
                                title: translate('text.preview_document'),
                            },
                            {
                                action: this.deleteResource,
                                icon: TrashIcon, // make this a function
                                visible: (it) => (checkPerm(this.getResource(), DELETE_PERM) && it.VendorCreditStatusID === 0),
                                label: false, // make this a function
                                title: translate('btn.delete'),
                                order: 30
                            }
                        ]}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                {this.state.deleteDialog && (
                    <ModalConfirm
                        {...this.props}
                        text={this.state.text}
                        name={this.state.confirmText}
                        submit={this.state.deleteDialog}
                        cancel={this.handleToggleDeleteDialog}
                        ui={{confirmDialogHideAnimation: this.state.confirmDialogHideAnimation}}
                    />)
                }
                <ModalConfirm
                    title={translate('text.confirm')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <VendorCreditModal
                    show={this.state.isVendorCreditModalOpen}
                    selectedItem={this.state?.selectedItem ? dialogResource.data ?? {} : undefined}
                    itemList={this.state?.selectedItem ? dialogResource?.data?.Items ?? [] : []}
                    isLoading={resource.isLoading || dialogResource.isLoading}
                    fetchOnMount={() => {
                        if (this.state.selectedItem) {
                            this.props.dispatch(getDialogResource({
                                user: LocalStorage.get('user'),
                                resource: this.getResource(),
                                query: {
                                    VendorCreditID: this.state?.selectedItem?.VendorCreditID
                                }
                            }))
                        }
                    }}
                    onSubmitCreate={(params, files) => {
                        this.props.dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            resource: this.getResource(),
                            piggyResource: this.getResource(),
                            file: files,
                            fileResource: Resources.ExpenseVendorCreditDocuments,
                            query: this.getQuery(),
                            errorMessage: true,
                            successMessage: this.props.translate("message.vendor_credit_create_success"),
                        }));
                    }}
                    onSubmitEdit={(params, files) => {
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            resource: this.getResource(),
                            piggyResource: this.getResource(),
                            file: files,
                            fileResource: Resources.ExpenseVendorCreditDocuments,
                            query: this.getQuery(),
                            errorMessage: true,
                            successMessage: this.props.translate("message.vendor_credit_edit_success"),
                        }));
                    }}
                    onClose={() => {
                        this.setState({
                            isVendorCreditModalOpen: false,
                            selectedItem: undefined
                        })
                        this.props.dispatch(resetDialogResource());
                    }}
                    translate={translate}
                    dispatch={this.props.dispatch}
                />

                <ModalDefault
                    show={this.state.PreviewDialog}
                    limitHeight={true}
                    widthClass={'max-w-5xl'}
                    title={translate('preview') + (this.state.previewDocument?.Description ? ' - ' + this.state.previewDocument?.Description : '')}

                    onButtonClick={this.handleDownloadDocument}
                    buttonLabel={translate('Download')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={this.toggleProcessPreview}
                    isLoading={false}
                >
                    {!!this.state.PreviewDialog && (
                        <FileViewer
                            fileType={this.state.previewDocument.AttachPath.split('.').pop()}
                            filePath={Env.getApiUrl('api/' + Resources.ExpenseVendorCreditDocuments, Object.assign({}, {
                                VendorCreditID: this.state.previewDocument?.VendorCreditID,
                                token: getJWT().access_token,
                                name: 'vendor_credit_' + this.state.previewDocument?.VendorCreditID
                            }))}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(VendorCreditTab)
