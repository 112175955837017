import React, {useEffect, useRef, useState} from "react";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, getDefaultQueryFields, getDefaultTableOptions, getUser, groupListBySCAC} from "../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {createResource, getResource, resetResource} from "../../../data/actions/resource";
import useQuery from "../../../hooks/use-query";
import {classNames, getLookup, getProp, openInNewTab, resourceIsCreated,} from "../../../common/util/util-helpers";
import {
    CheckCircleIcon,
    ChevronUpIcon as ChevronUpSolidIcon,
    Cog6ToothIcon,
    EyeIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import {cloneDeep, genericMoneyFormatter} from "../../../common/util/util-vanilla";
import {DEFAULT_DATABASE_DATETIME_FORMAT, READ_PERM} from "../../../util/util-constants";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import WorkOrderStatusBadge from "../../../common/components/badge/work-order-status-badge/index";
import Page from "../../../common/components/layout/layout-components/page";
import Tippy from "@tippyjs/react";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import ModalDefault from "../../../common/components/modal/modal-default";
import FieldsForm from "../../../common/components/fields/fields-form";
import FieldOptions from "../../../common/components/fields/field-options";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import moment from "moment/moment";
import {formatMoney} from "../../../common/util/util-formaters";
import InfoParagraph from "../../../common/components/info-paragraph";
import LocalStorage from "../../../util/localStorage";
import {LoaderLarge} from "../../../common/components/loader";
import EmailBill from "./email-bill";
import {CREATE_PERM} from "../../../common/util/util-consts";

const CheckboxCell = ({value}) => {
    return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
        : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
}
export default function CustomerBillingWorkOrder({history, match, translate}) {
    /** Store
     ================================================================= */
    const prevCreateIdRef = useRef(0);

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);
    const count = getProp(resource.data, "count", 0);

    const [selectedRows, setSelectedRows] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [isProcessInvoiceModalOpen, setIsProcessInvoiceModalOpen] = useState(false);
    const [invoiceFields, setInvoiceFields] = useState(() => getInvoiceFields());
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [invoiceID, setInvoiceID] = useState(null);
    const workOrderStatuses = getLookup("WorkOrderStatus");

    let LaborAmount;
    let PartsAmount;
    let SurchargeAmount;
    let MiscAmount;

    if (selectedItem) {
        LaborAmount = selectedItem.LaborAmount;
        PartsAmount = selectedItem.PartsAmount;
        SurchargeAmount = selectedItem.SurchargeAmount;
        MiscAmount = selectedItem.MiscAmount;
    }

    const totalAmountSelected = Object.values(selectedRows).reduce((memo, it) => {
        const totalAmount = it.TotalAmount ?? 0;
        memo = memo + totalAmount;

        return memo;
    }, 0)


    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath());
    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), {
        searchFields: JSON.stringify({
            WorkOrderStatusID: queryFields.WorkOrderStatusID?.value,
            RequestedServiceCategoryID: queryFields.RequestedServiceCategoryID?.value,
            TruckID: queryFields.TruckID?.value?.value,
            TrailerID: queryFields.TrailerID?.value?.value,
            AssignedVendorID: queryFields.AssignedVendorID?.value?.value,
            AssignedContactID: queryFields.AssignedContactID?.value?.value,
        })
    });

    const [tableOptions, setTableOptions] = useState(
        getDefaultTableOptions(getFields(), {
            behaviour: {
                rowSelect: true,
                hasMenu: true,
                canAdjustWidth: true
            }
        }, getListPath(), translate)
    );
    const [selectedOrganization, setSelectedOrganization] = useState({})
    const [isOrganizationInfoDialogOpen, setIsOrganizationInfoDialogOpen] = useState(false);

    useEffect(() => {
        fetchData(dispatch, query);
    }, [queryFields]);

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: getUser(),
            resource: getResourcePath(),
            query: query
        }))
    }

    const handleSubmitProcessInvoiceClick = () => {
        const validFields = FieldsManager.validateFields(Object.assign({}, invoiceFields));

        if (FieldsManager.checkFieldsForErrors(validFields)) {
            const params = FieldsManager.getFieldKeyValues(validFields);

            delete params.AutoCounter;
            delete params.Customer;
            delete params.Office;

            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: params,
                resource: Resources.BillingWorkOrders,
                query: query,
                piggyResource: getResourcePath(),
            }))
        } else {
            setInvoiceFields(validFields);
        }
    }

    /** UI events
     ================================================================= */
    const handleEditResourceClick = (item) => {
        openInNewTab('/work-orders/' + item.WorkOrderID);
    }

    const handleSelectRowClick = (item) => {
        const itemID = item.WorkOrderID

        let selectedRowsUpdate = cloneDeep(selectedRows)

        if (selectedRowsUpdate[itemID]) {
            delete selectedRowsUpdate[itemID]
        } else {
            Object.assign(selectedRowsUpdate, {[itemID]: item})
        }

        setSelectedRows(selectedRowsUpdate)
    }

    const handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(resource.data, 'list', []))

        let selectedRowsClone = cloneDeep(selectedRows)

        if (!selectAll) {
            Object.assign(selectedRowsClone, data.reduce((memo, it) => {
                memo[it.WorkOrderID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsCloneKeys = Object.keys(selectedRowsClone)
            data.forEach(it => {
                if (selectedRowsCloneKeys.includes(it.WorkOrderID.toString())) {
                    delete selectedRowsClone[it.WorkOrderID]
                }
            })
        }

        setSelectedRows(selectedRowsClone)
    }

    const handleViewTruckClick = (truckID) => {
        dispatch(showGlobalModal('ViewTruckCard', truckID));
    }

    const handleViewTrailerClick = (trailerID) => {
        dispatch(showGlobalModal('ViewTrailerCard', trailerID));
    }

    const handleViewContactClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleViewOrganizationClick = (item) => {
        setSelectedOrganization(item)
        setIsOrganizationInfoDialogOpen(true)
    }

    const handleProcessInvoiceClick = (item) => {
        setInvoiceFields(getInvoiceFields(item));
        setSelectedItem(item);
        setIsProcessInvoiceModalOpen(true);
    }

    function getInvoiceFields(item = {}) {
        const fieldsTemplate = {
            Customer: new Field('Customer', item.ChargeOrganization ? item.ChargeOrganization : item.ChargeContact, [], true, 'text', {addContainerClass: "col-span-12"}),
            Office: new Field('Office', '', [], true, 'text', {addContainerClass: "col-span-8"}),
            AutoCounter: new Field('AutoCounter', '', [], true, 'text', {
                label: translate("text.wo_number"),
                addContainerClass: "col-span-4"
            }),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['required'], false, 'date', {addContainerClass: "col-span-4"}),
            DueDate: new Field('DueDate', moment().add(15, 'days').format(DEFAULT_DATABASE_DATETIME_FORMAT), ['required'], false, 'date', {addContainerClass: "col-span-4"}),
            BookDate: new Field('BookDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['required'], false, 'date', {addContainerClass: "col-span-4"}),
            WorkOrderID: new Field('WorkOrderID', '', [''], false, 'hidden'),
        }


        fieldsTemplate.Customer.metadata.fieldOptions = (it) => {
            const isContact = !item.ChargeOrganization;
            const id = item.ChargeContactID || item.ChargeOrganizationID;

            return id
                ? <FieldOptions
                    options={[
                        {
                            icon: InformationCircleIcon,
                            onClick: () => {
                                if (isContact) {
                                    dispatch(showModal('ViewContactCard', {"ContactID": item.ChargeContactID}));
                                } else {
                                    dispatch(showModal('ViewCustomerCard', {
                                        OrganizationID: item.ChargeOrganizationID
                                    }))
                                }
                            },
                            isVisible: !!it.value
                        }
                    ]}
                />
                : null

        }

        return fillFieldsFromData(fieldsTemplate, item);
    }

    /** Helpers
     ================================================================= */
    function getFields(item = {}) {
        const TruckID = !!item?.TruckID;
        const TrailerID = !!item?.TrailerID;

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'text'),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
                , omitSort: true
            }),
            WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                render: (it) => <WorkOrderStatusBadge
                    workOrderStatuses={workOrderStatuses}
                    statusID={it.WorkOrderStatusID}
                />
            }),
            TruckID: new Field('TruckID', '', TrailerID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.TrucksInfo, READ_PERM) && handleViewTruckClick(item.TruckID)}
                            >
                                {item.Truck}
                            </CellButton>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                },
                addContainerClass: "col-span-6 col-start-1"
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', '', TruckID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.Trailer, READ_PERM) && handleViewTrailerClick(item.TrailerID)}
                            >
                                {item.Trailer}
                            </CellButton>
                        )
                    } else {
                        return item.Trailer;
                    }
                },
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: "ChargeTo",
                render: (item) => {
                    if (item.ChargeTo == 2) {
                        return ("Non-Chargeable")
                    }
                    if (item.ChargeContactID) {
                        return <CellButton
                            onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewContactClick(item.ChargeContactID)}
                        >
                            {item.ChargeContact}
                        </CellButton>
                    }
                    if (item.ChargeOrganizationID) {
                        return <CellButton
                            onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewOrganizationClick(item.ChargeOrganizationID)}
                        >
                            {item.ChargeOrganization}
                        </CellButton>
                    }
                }
            }),
            TotalAmount: new Field('TotalAmount', '', [''], false, "float", {
                render: (it) => (
                    <Tippy
                        content={
                            <div className={"text-right"}>
                                <div>Labor: {genericMoneyFormatter(it.LaborAmount)}</div>
                                <div>Parts: {genericMoneyFormatter(it.PartsAmount)}</div>
                                <div>Shop supplies: {genericMoneyFormatter(it.MiscAmount)}</div>
                                <div>Misc. surcharge: {genericMoneyFormatter(it.SurchargeAmount)}</div>
                            </div>
                        }
                    >
                        <div className={"text-right"}>
                            {genericMoneyFormatter(it.TotalAmount)}
                        </div>
                    </Tippy>
                ),
                colFilter: true
            }, {icon: false}),
            IsAuthorized: new Field('IsAuthorized', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsAuthorized}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    useEffect(() => {
        if (resourceIsCreated(resource) && prevCreateIdRef.current !== resource?.create?.id) {
            setInvoiceID(resource.create.id);
            prevCreateIdRef.current = resource.create.id;
            setIsProcessInvoiceModalOpen(false);
            setIsEmailModalOpen(true);
        }

        return () => {
            if (resource?.create?.id) {
                dispatch(resetResource());
                setIsEmailModalOpen(false);
                setInvoiceID(null);
                prevCreateIdRef.current = 0;
            }
        }
    }, [resource]);


    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <Layout
                history={history}
                match={match}
                translate={translate}
            >
                <Page>
                    <PageHeader
                        title={translate("page_title.customer-billing")}
                        // buttons={[
                        //     {
                        //         label: translate("btn.create"),
                        //         type: "primary",
                        //         onClick: handleCreateResourceClick,
                        //         isHidden: !checkPerm(getResourcePath(), CREATE_PERM)
                        //     }
                        // ]}
                        afterTitle={
                            <>
                                {/*{!!resource.isLoading && (*/}
                                {/*    <LoaderSmall/>*/}
                                {/*)}*/}
                                {/*{!resource.isLoading && (*/}
                                {/*    <div>Total amount: {*/}
                                {/*        genericMoneyFormatter(*/}
                                {/*            getProp(resource.data, 'totals.TotalPrice', 0)*/}
                                {/*            +*/}
                                {/*            getProp(resource.data, 'totals.TotalAccessorialAmount', 0)*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </>
                        }
                    />

                    <ResourceList
                        fields={getFields()}
                        resource={resource}
                        isLoading={isLoading}
                        queryFields={queryFields}
                        setQueryFields={setQueryFields}
                        listPath={getListPath()}
                        dispatch={dispatch}
                        query={query}
                        onSelectRow={handleSelectRowClick}
                        onSelectAllClick={handleSelectAllClick}
                        selectedRows={selectedRows}
                        onRefreshTable={fetchData}
                        translate={translate}
                        selects={getSelects()}
                        actions={[
                            {
                                icon: Cog6ToothIcon,
                                title: translate("btn."),
                                hasPerm: checkPerm(Resources.BillingWorkOrders, CREATE_PERM),
                                action: handleProcessInvoiceClick
                            }, {
                                icon: EyeIcon,
                                title: translate("btn.view_work_order"),
                                action: handleEditResourceClick
                            }
                        ]}
                        tableKey={"WorkOrderID"}
                        onRowClick={handleProcessInvoiceClick}
                    />

                    <TableBulkActions
                        selectedRows={selectedRows}
                        tableKey="WorkOrderID"
                        fields={getFields()}
                        translate={translate}
                        options={tableOptions}
                        setSelectedRows={setSelectedRows}
                        onSelectAllClick={handleSelectAllClick}
                        footerContent={<div className="leading-9 px-4 font-bold">Total amount for selected entries:
                            ${totalAmountSelected}</div>}
                    >
                        <div className='flex items-center h-8'>
                            <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>
                                <div className='flex gap-x-1 pr-2'>


                                    <div className='flex gap-x-1 px-2'>
                                        <TableTagManager
                                            rowKey={'WorkOrderID'}
                                            btnIconClass='w-5 h-5'
                                            customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                            selectedRows={selectedRows}
                                            resourceName={Resources.ColorTagWorkOrders}
                                            piggyResourceName={getResourcePath()}
                                            query={query}
                                            onAfterTagRows={() => {
                                                setSelectedRows({})
                                            }}
                                            translate={translate}
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>
                    </TableBulkActions>

                    {(isOrganizationInfoDialogOpen &&
                        <OrganizationInfoDialog
                            show={isOrganizationInfoDialogOpen}
                            translate={translate}
                            organizationID={selectedOrganization}
                            handleClose={() => setIsOrganizationInfoDialogOpen(false)}
                        />
                    )}
                </Page>
            </Layout>

            <ModalDefault
                show={isProcessInvoiceModalOpen}
                widthClass={'max-w-3xl'}
                title={translate("text.process_invoice")}

                closeButtonLabel={translate('Close')}
                onClose={() => setIsProcessInvoiceModalOpen(false)}
                onButtonClick={handleSubmitProcessInvoiceClick}
                buttonLabel={translate("btn.process")}
                customHtmlLeft={<div className="h-10 flex items-center">
                    <InfoParagraph>
                        {translate('text.ProcessInvoiceInfo')}
                    </InfoParagraph>
                </div>}
            >
                {isLoading && (
                    <LoaderLarge/>
                )}

                <div className="grid grid-cols-12 gap-4 p-6">
                    <FieldsForm
                        fieldsState={invoiceFields}
                        onChange={() => null}
                        isLoading={isLoading}
                        translate={translate}
                    />
                </div>

                <div className="p-6">
                    <InfoParagraph>
                        {translate('text.ProcessInvoiceInfoDefault')}
                    </InfoParagraph>
                </div>

                <div className="flex flex-col w-1/4 ml-auto p-6 space-y-1.5">
                    <div className="flex justify-between items-center">
                        <dt className="text-sm font-medium text-tm-gray-700">Labor</dt>
                        <dd className="ml-5 text-sm text-tm-gray-900">${formatMoney(LaborAmount)}</dd>
                    </div>
                    <div className="flex justify-between items-center">
                        <dt className="text-sm font-medium text-tm-gray-700">Parts</dt>
                        <dd className="ml-5 text-sm text-tm-gray-900">${formatMoney(PartsAmount)}</dd>
                    </div>
                    <div className="flex justify-between items-center">
                        <dt className="text-sm font-medium text-tm-gray-700">Shop supplies</dt>
                        <dd className="ml-5 text-sm text-tm-gray-900">${formatMoney(SurchargeAmount)}</dd>
                    </div>
                    <div className="flex justify-between items-center">
                        <dt className="text-sm font-medium text-tm-gray-700">Misc. surcharge</dt>
                        <dd className="ml-5 text-sm text-tm-gray-900">${formatMoney(MiscAmount)}</dd>
                    </div>
                    <div className="flex justify-between items-center">
                        <dt className="text-sm font-bold">Invoice Total</dt>
                        <dd className="ml-5 text-sm text-tm-gray-900 font-bold">
                            ${formatMoney(Number(LaborAmount ?? 0) + Number(PartsAmount ?? 0) + Number(SurchargeAmount ?? 0) + Number(MiscAmount ?? 0))}
                        </dd>
                    </div>
                </div>
            </ModalDefault>

            <EmailBill
                show={isEmailModalOpen}
                onClose={() => setIsEmailModalOpen(false)}
                InvoiceID={invoiceID}
                selectedItem={selectedItem}
                query={query}
                translate={translate}
            />
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getPrimaryField = () => "WorkOrderID";
const getResourcePath = () => Resources.WorkOrdersBilling;

const getListPath = () => getResourcePath() + "_list"


const getQueryFields = (translate) => {
    return (
        Object.assign(
            getDefaultQueryFields(getPrimaryField(), translate),
            {
                archived: new Field('archived', '', [''], false, 'checkbox', {
                    labelType: "float",
                    label: "archived",
                    hasActiveBadge: true,
                }),
                WorkOrderStatusID: new Field('WorkOrderStatusID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
                RequestedServiceCategoryID: new Field('RequestedServiceCategoryID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
                TruckID: new Field('TruckID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                TrailerID: new Field('TrailerID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                AssignedVendorID: new Field('AssignedVendorID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
                AssignedContactID: new Field('AssignedContactID', '', [], false, 'select-search', {hasActiveBadge: true}, {isClearable: true}),
            }
        )
    )
}


function getSelects() {
    return {
        AssignedContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        RequesterID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        AssignedVendorID: {
            api: 'api/' + Resources.Vendors,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.VendorID,
            })
        },
        TruckID: {
            api: 'api/' + Resources.Trucks,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Truck')
            }
        },
        TrailerID: {
            api: 'api/' + Resources.Trailers,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Trailer')
            }
        },
        CustomCodeID: {
            api: 'api/' + Resources.WorkOrdersCustomCodes,
            query: {},
            searchMap: (it) => ({
                label: it.CustomCode,
                value: it.CustomCodeID,
            })
        },
        VMRSCode: {
            api: 'api/' + Resources.WorkOrdersVMRSCode,
            query: {},
            searchMap: (it) => {
                return ({
                    label: it.value,
                    value: it.value,
                })
            }
        },
        WorkOrderStatusID: getLookup('WorkOrderStatus'),
        RequestedServiceCategoryID: getLookup('RequestedServiceCategory'),
    }
}
