import React, {useEffect, useState} from "react";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import ResourceTable from "../../../common/components/resource-table";
import {checkPerm} from "../../../common/util/util-helpers";
import {CREATE_PERM, DEFAULT_QUERY, DELETE_PERM, UPDATE_PERM} from "../../../util/util-constants";
import {Field, FieldsManager} from "../../../data/services/fields";
import {createResource, deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import useQuery from "../../../hooks/use-query";
import Button from "../../../common/components/button";
import FieldSearch from "../../../common/components/fields/field-text/search";

export function getPartManufacturerFields(item = {}) {
    const fieldTemplates = {
        Manufacturer: new Field('Manufacturer', '', ['empty'], false, 'text', {addContainerClass: "col-span-full"}),
    }

    return fillFieldsFromData(fieldTemplates, item);
}

export function getPartManufacturerQueryFields(item = {}) {
    return {
        query: new Field('query', '', [''], false, 'search', {}, {}),
        sort: new Field('sort', 'ASC', [''], false, 'text', {}, {}),
        sortBy: new Field('sortBy', 'Manufacturer', [''], false, 'text', {}, {}),
        offset: new Field('offset', "0", [''], false, 'text', {}, {}),
        limit: new Field('limit', '10', [''], false, 'select', {
            labelType: 'float', hideLabel: true
        }, {menuPlacement: 'top'})
    }
}

export default function PartManufacturers({translate}) {
    const dispatch = useDispatch();
    const resource = useSelector(state => state.resource);
    const isLoading = resource.isLoading;
    const data = resource?.data?.list ?? [];
    const count = resource?.data?.count ?? 0;

    const [queryFields, setQueryFields] = useQuery(getPartManufacturerQueryFields(translate), 'part-Manufacturers-tab')
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const [isPartLocationModalOpen, setIsPartLocationModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);

    function fetchData() {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourcePath(),
            query: query
        }))
    }

    function handleConfirmDeleteClick() {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: {
                ManufacturerID: selectedItem.ManufacturerID
            },
            piggyQuery: query,
            errorMessage: true,
            successMessage: translate(`message.archived_generic`, [selectedItem.Manufacturer]),
            resource: getResourcePath(),
            piggyResource: getResourcePath()
        }));

        setIsDeleteConfirmModalOpen(false);
    }

    function handleQueryChange(name, value) {
        let queryFieldsUpdate = Object.assign({}, queryFields);

        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);

        setQueryFields(queryFieldsUpdate);
    }

    function handleCreateClick() {
        setIsPartLocationModalOpen(true);
        setSelectedItem({});
    }

    function handleEditClick(item) {
        setIsPartLocationModalOpen(true);
        setSelectedItem(item);
    }

    function handleDeleteClick(item) {
        setIsDeleteConfirmModalOpen(true);
        setSelectedItem(item);
    }

    function getResourcePath() {
        return Resources.WorkOrderProductsManufacturers;
    }

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    useEffect(() => {
        if (data.length === 0 && count > 0 && !isLoading && !query.query) {
            handleQueryChange("offset", "0");
        }
    }, [data, isLoading, count, query]);

    return (
        <>
            <div className="grid gap-4 py-8 max-w-3xl mx-auto">
                {(((count !== 0) && !isLoading) || !!queryFields?.query?.value) && (
                    <div className="flex items-center justify-between">
                        <FieldSearch
                            addClass={"form-control px-6"}
                            name={"query"}
                            onChange={(name, value) => handleQueryChange(name, value)}
                            value={queryFields.query.value}
                        />

                        <Button appearance="primary" onClick={handleCreateClick}>
                            {translate("btn.create")}
                        </Button>
                    </div>
                )}

                <ResourceTable
                    addTableClass="isolate rounded-card border-2 border-tm-gray-300"
                    data={data}
                    count={count}

                    fields={getPartManufacturerFields()}
                    translate={translate}
                    isLoading={isLoading}

                    onDelete={checkPerm(getResourcePath(), DELETE_PERM) ? handleDeleteClick : null}
                    onEdit={checkPerm(getResourcePath(), UPDATE_PERM) ? handleEditClick : null}
                />

                {count > 0 && (
                    <div className="pl-4">
                        <Pagination
                            count={count}
                            queryFields={queryFields}
                            handleQueryChange={handleQueryChange}
                            translate={translate}
                        />
                    </div>
                )}

                <NoRecordsTable
                    show={(count === 0) && !isLoading}
                    canCreate={checkPerm(getResourcePath(), CREATE_PERM)}
                    title={translate("text.no_records")}
                    filters={queryFields}
                    excludeFields={['limit', 'sort', 'sortBy', 'offset']}
                    clearFilterBtnLabel="Clear search input"
                    onClearFilterClick={() => handleQueryChange("query", "")}
                    text={translate("text.no_manufacturers")}
                    btnLabel={translate("btn.create_manufacturers")}
                    onBtnClick={handleCreateClick}
                />

            </div>

            <ModalSaveResource
                title={!!selectedItem.ManufacturerID ? "Edit manufacturer" : "Create manufacturer"}
                widthClass="max-w-3xl"
                gridColsClass={"grid-cols-12"}
                show={isPartLocationModalOpen}
                translate={translate}
                fields={getPartManufacturerFields(selectedItem)}
                onClose={() => setIsPartLocationModalOpen(false)}
                onSubmit={(params, fields) => {
                    if (params) {
                        if (selectedItem.ManufacturerID) {
                            dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign(
                                    params,
                                    {ManufacturerID: selectedItem.ManufacturerID}
                                )
                                ,
                                query: query,
                                resource: getResourcePath(),
                                piggyResource: getResourcePath(),
                                errorMessage: true,
                                successMessage: `Manufacturer has been updated`,
                            }));
                        } else {
                            dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: query,
                                resource: getResourcePath(),
                                piggyResource: getResourcePath(),
                                errorMessage: true,
                                successMessage: `Manufacturer has been added`,
                            }));
                        }
                        setIsPartLocationModalOpen(false);
                    }
                }}

                metadata={{
                    ManufacturerParentID: {
                        api: 'api/' + getResourcePath(),
                        query: DEFAULT_QUERY,
                        searchMap: (item) => ({
                            value: item.ManufacturerID,
                            label: item.Manufacturer
                        })
                    }
                }}
            />

            <ModalConfirm
                title={translate("text.Confirm")}
                show={isDeleteConfirmModalOpen}
                text={translate("text.delete_part_manufacturer")}
                onClose={() => {
                    setIsDeleteConfirmModalOpen(false);
                }}
                buttonLabel={translate("btn.confirm")}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmDeleteClick}
            />
        </>
    )
}