import {Loader, LoaderLarge} from "../../../../../common/components/loader";
import ChatbotBox from "../../../../../common/components/layout/layout-components/AIChat/chatbot-box";
import React, {useEffect, useState} from "react";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import QuestionMarkCircleIcon from "@heroicons/react/24/outline/QuestionMarkCircleIcon";
import FieldTextarea from "../../../../../common/components/fields/field-textarea";
import FieldContainer from "../../../../../common/components/fields/field-container";
import Bot from "../../../../../assets/images/bot.png";
import FieldCheckbox from "../../../../../common/components/fields/field-checkbox";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import {classNames} from "../../../../../common/util/util-helpers";
import FieldText from "../../../../../common/components/fields/field-text";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import CopyToClipboardButton from "../../../../../common/components/buttons/copy-to-clipboard-button";

export default function WorkOrdersLaborPartsAiModal({
                                                        aiLoading,
                                                        aiData,
                                                        translate,
                                                        additionalAiInfo,
                                                        setAdditionalAiInfo,
                                                        dataUnit,
                                                        setItemsChecked,
                                                        getAiReconRefreshItem,
                                                        aiItemLoading
                                                    }) {
    const getFields = (item = null) => {

        return {
            itemChecked: new Field('itemChecked', 1, [''], false, 'checkbox', {
                addContainerClass: 'col-span-full',
            }),
            Complaint: new Field('Complaint', item.Complaint, [''], false, 'text', {
                addContainerClass: 'col-span-6'
            }),
            Refresh: new Field('Refresh', '', [''], false, 'custom')
        }
    }

    const handleInputChange = (name, value, index, fieldsChecked) => {
        setFields(prevState => ({
            ...prevState,
            [index]: FieldsManager.updateField(fieldsChecked, name, value)
        }))

        setItemsChecked(fields)
    }
    const [areActionItemsVisible, setAreActionsItemsVisible] = useState(false);
    const [areSuggestedQuestionsVisible, setAreSuggestedQuestionsVisible] = useState(false);
    const [arePostRepairActionsVisible, setArePostRepairActionsVisible] = useState(false);
    const [fields, setFields] = useState([])

    useEffect(() => {
        if (aiData?.ActionItems) {
            aiData?.ActionItems.map((it, index) => {

                setFields(prevState => ({
                    ...prevState,
                    [index]: getFields(it)
                }))
            })
        }
    }, [aiData?.ActionItems]);

    useEffect(() => {
        setItemsChecked(fields)
    }, [fields]);

    return (
        <>
            {aiLoading && (
                <>
                    <div className="h-64"/>
                    <LoaderLarge addClass={""}/>
                </>
            )}

            {!aiLoading && (
                <div className="p-6 text-base space-y-4">
                    <div
                        className="text-base flex rounded-btn items-center gap-4 bg-tm-gray-100 border-2 border-tm-gray-200 px-2 py-1">
                        <div
                            className='w-12 h-12 bg-tm-gray-50 border-2 border-tm-gray-300 p-2 rounded-full text-tm-gray-700 flex-shrink-0'>
                            <img src={Bot} alt="bot"/>
                        </div>

                        <ChatbotBox
                            fakeStreamSpeed={20}
                            streamContent={true}
                            content={`Based on the initial complaint, here is what I would recommend following actions: \n (${dataUnit.Type ?? ""} ${dataUnit.Year ?? ""} ${dataUnit.Manufacturer ?? ""} ${dataUnit.Model ?? ""} ${dataUnit.Engine ?? ""})`}
                            onEndStream={() => setAreActionsItemsVisible(true)}
                        />
                    </div>

                    {areActionItemsVisible && (<>
                            {aiData?.ActionItems.map((it, index) => {
                                return (
                                    !aiItemLoading[index] ? (
                                        <>
                                            <div className={"flex"}>
                                                <h3 className={"text-lg font-bold"}>#{index + 1}</h3>
                                                <button className={""}
                                                        onClick={() => getAiReconRefreshItem(fields[index].Complaint.value, index)}
                                                ><ArrowPathIcon className={
                                                    classNames(
                                                        "w-6 h-6 mt-0.5 ml-2",
                                                        // isLoading ? "animate-spin" : undefined,
                                                    )
                                                }/></button>
                                            </div>
                                            <div className="flex items-center mt-3 mb-5">
                                                <FieldCheckbox
                                                    id={'itemChecked-' + index}
                                                    value={fields[index].itemChecked.value}
                                                    onChange={() => handleInputChange("itemChecked", !fields[index].itemChecked.value, index, fields[index])}
                                                    name={"itemChecked"}
                                                />
                                            </div>
                                            <div className="grid grid-cols-12 gap-4">
                                                <div className="col-span-6 flex flex-col">
                                                    {/*<div>Complaint</div>*/}
                                                    {/*<div className="form-control flex-1 p-4">{it.Complaint}</div>*/}
                                                    <FieldContainer
                                                        item={fields[index].Complaint}
                                                        translate={translate}
                                                    >
                                                        <FieldText
                                                            addClass="form-control"
                                                            value={fields[index].Complaint.value}
                                                            onChange={(name, value) => handleInputChange("Complaint", value, index, fields[index])}
                                                            placeholder={""}
                                                        />
                                                    </FieldContainer>
                                                </div>

                                                <div className="col-span-6 flex flex-col">
                                                    <div>Correction</div>
                                                    <div className="form-control flex-1 p-4">{it.Correction}</div>
                                                </div>
                                            </div>

                                            <div className="overflow-hidden rounded-btn border border-tm-gray-200">
                                                <table className="table-auto w-[calc(100%+2px)] -m-px">
                                                    <thead>
                                                    <tr className="bg-tm-gray-50">
                                                        <th className="h-9 px-6 border border-tm-gray-200">Labor
                                                            Description
                                                        </th>
                                                        <th className="h-9 px-6 border border-tm-gray-200">Labor Hours
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {it?.Labor?.map(laborItem => {
                                                        return (
                                                            <tr className="bg-inverse">
                                                                <td className="h-9 px-6 border border-tm-gray-200">{laborItem.LaborDescription}</td>
                                                                <td className="h-9 px-6 border border-tm-gray-200">{laborItem.LaborHours}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>) : (<>
                                        <div className="h-20"/>
                                        <div className="flex items-center justify-center">
                                            <Loader disableContainer={true}/>
                                        </div>
                                    </>))
                            })}

                            <div className="pt-10">
                                <div
                                    className="text-base flex rounded-btn items-center gap-4 bg-tm-gray-100 border-2 border-tm-gray-200 px-2 py-1">
                                    <div
                                        className='w-12 h-12 bg-tm-gray-50 border-2 border-tm-gray-300 p-2 rounded-full text-tm-gray-700 flex-shrink-0'>
                                        <img src={Bot} alt="bot"/>
                                    </div>

                                    <ChatbotBox
                                        fakeStreamSpeed={20}
                                        streamContent={true}
                                        content={"Also, here are some of the additional questions that you could ask a customer?"}
                                        onEndStream={() => setAreSuggestedQuestionsVisible(true)}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {areSuggestedQuestionsVisible && (
                        <>
                            <ul className="pl-4 pb-10">
                                {aiData?.SuggestedQuestions.map(it => {
                                    return (
                                        <li className="list-disc text-tm-gray-600 p-1 italic">
                                            {it}
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                    )}

                    <div>
                        <InfoBar Icon={QuestionMarkCircleIcon} iconClass="w-6 h-6 shrink-0 text-sky-600">
                            <ChatbotBox
                                fakeStreamSpeed={20}
                                streamContent={true}
                                content={"Would you like to add more info? Answer of one, or more of these questions and click \"Ask again\" button."}
                            />
                        </InfoBar>

                        <div className="flex gap-4">
                            <div className="flex-1">
                                <FieldContainer
                                    label=""
                                    translate={translate}
                                >
                                    <FieldTextarea
                                        addClass="form-control pt-2"
                                        rows="3"
                                        value={additionalAiInfo ?? ""}
                                        name={"AdditionalAiInfo"}
                                        onChange={(_, value) => setAdditionalAiInfo(value)}
                                    />
                                </FieldContainer>
                            </div>
                        </div>
                    </div>

                    <div className="pt-10">
                        <div
                            className="text-base flex rounded-btn items-center gap-4 bg-tm-gray-100 border-2 border-tm-gray-200 px-2 py-1">
                            <div
                                className='w-12 h-12 bg-tm-gray-50 border-2 border-tm-gray-300 p-2 rounded-full text-tm-gray-700 flex-shrink-0'>
                                <img src={Bot} alt="bot"/>
                            </div>

                            <ChatbotBox
                                fakeStreamSpeed={60}
                                streamContent={true}
                                content={"Post repair actions"}
                                onEndStream={() => setArePostRepairActionsVisible(true)}
                            />
                        </div>
                    </div>

                    {!!arePostRepairActionsVisible && (
                        <div className="space-y-6">
                            {!aiLoading && !!aiData?.PostRepairActions && aiData.PostRepairActions.map(it => {
                                return <dl
                                    className="divide-y divide-tm-gray-300 border border-tm-gray-300 px-10 rounded-btn">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-tm-gray-900">Type</dt>
                                        <dd className="mt-1 text-sm leading-6 text-tm-gray-700 sm:col-span-3 sm:mt-0">
                                            {it.Type}

                                            {
                                                it.Type
                                                    ? <span className="relative">
                                            <CopyToClipboardButton
                                                className={"absolute w-6 h-6 -translate-y-1.5 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-link text-tm-gray-600 hover:text-tm-gray-900 group ml-2.5"}
                                                clipboardText={it.Type}
                                                translate={translate}
                                            />
                                        </span>
                                                    : undefined
                                            }
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-tm-gray-900"> Message
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-tm-gray-700 sm:col-span-3 sm:mt-0">
                                            {it.Message}

                                            {
                                                it.Message
                                                    ? <span className="relative">
                                            <CopyToClipboardButton
                                                className={"absolute w-6 h-6 -translate-y-1.5 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-link text-tm-gray-600 hover:text-tm-gray-900 group ml-2.5"}
                                                clipboardText={it.Message}
                                                translate={translate}
                                            />
                                        </span>
                                                    : undefined
                                            }
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-tm-gray-900"> Purpose
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6  text-tm-gray-700 sm:col-span-3 sm:mt-0">
                                            {it.Purpose}

                                            {
                                                it.Purpose
                                                    ? <span className="relative">
                                                <CopyToClipboardButton
                                                    className={"absolute w-6 h-6 -translate-y-1.5 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-link text-tm-gray-600 hover:text-tm-gray-900 group ml-2.5"}
                                                    clipboardText={it.Purpose}
                                                    translate={translate}
                                                />
                                        </span>
                                                    : undefined
                                            }
                                        </dd>
                                    </div>
                                </dl>
                            })}

                        </div>
                    )}
                </div>
            )}
        </>
    )
}